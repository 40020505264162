import { extendObservable, action } from 'mobx';
import { getSearchResult } from '../service';

export default class AppServicePlatformSearch {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      loading: false, // 是否显示加载状态
      submiting: false,
      pageNo: 1,
      searchResultList: [],
      totalCount: '',
      hasMore: false,
      searchKey: '',
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };

  @action updatePageNo = () => {
    this.pageNo = this.pageNo + 1;
  };

  // 获取搜索结果
  @action getSearchResult = (params) => {
    // 初始化的时候设置loading 后面再加载的时候不用再设置
    if (this.pageNo === 1) {
      this.loading = true;
    }

    getSearchResult(params).then(({ success, items, totalCount }) => {
      if (success) {
        this.searchResultList = this.searchResultList.concat(items);

        this.totalCount = totalCount;

        this.hasMore = this.searchResultList.length < totalCount;
      }
      return success;
    });
  };
}
