import { extendObservable, action } from 'mobx';

import { getSolutionsByFilter } from '../service';

export default class APPSolution {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      loading: false, // 是否显示加载状态
      bannersInfo: [],
      classify: [],
      colNum: 4,
      solutionList: [],
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  @action getSolutionsByFilter = (params) => {
    return getSolutionsByFilter(params).then(({ items, success }) => {
      if (success) {
        this.solutionList = items;
      }
    });
  };

  // 获取分类类型名称
  @action getClassifyName(list, children) {
    return list.filter((item) => {
      let state = false;
      children.forEach((ele) => {
        if (ele.classify === item.classify) {
          state = true;
          item.classifyName = ele.classifyName;
        }
      });
      return state;
    });
  }

  // 根据当前页面宽度以及单个视频宽度计算单行可展示视频数量
  @action calculateCurrentLineAppNum = (docWidth) => {
    const paddingWidth = 80 * 2;
    const APPWidth = 400;
    const contentWidth = docWidth - paddingWidth;
    const num = parseInt(contentWidth / APPWidth, 10);

    this.colNum = num > 4 ? 4 : num;
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };
}
