import { extendObservable, action } from 'mobx';
import { getBanners, getAPPByType, queryDictList } from '../service';

export default class AppServicePlatform {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      loading: false, // 是否显示加载状态
      submiting: false,
      bannersInfo: [],
      APPTypeMap: {},
      appTypeClassifyList: [],
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };

  // 按类型获取APP
  @action getAPPByType = (params) =>
    getAPPByType(params).then(({ success, items }) => {
      if (success) {
        const { parentClassifyId } = params;
        const newAPPTypeMap = { ...this.APPTypeMap };
        newAPPTypeMap[parentClassifyId] = [...items];
        this.APPTypeMap = newAPPTypeMap;
      }
      return success;
    });
}
