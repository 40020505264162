import AppGlobalModel from '../models/Global.js';
import ExceptionError from '../models/ExceptionError.model.js';
import Index from '../models/Index.model.js';
import APPDetail from '../models/APPDetail.model.js';
import Purchase from '../models/Purchase.model.js';
import AppServicePlatform from '../models/AppServicePlatform.model.js';
import AppServicePlatformMore from '../models/AppServicePlatformMore.model.js';
import AppServicePlatformDetail from '../models/AppServicePlatformDetail.model.js';
import AppServicePlatformSearch from '../models/AppServicePlatformSearch.model.js';
import OrderDetail from '../models/OrderDetail.model.js';
import APPSearch from '../models/APPSearch.model.js';
import APPDeveloper from '../models/APPDeveloper.model.js';
import APPSolution from '../models/APPSolution.model.js';
import APPSolutionDetail from '../models/APPSolutionDetail.model.js';
import APPSolutionSearch from '../models/APPSolutionSearch.model.js';
import APPSolutionConsult from '../models/APPSolutionConsult.model.js';

export default {
  appGlobalModel: new AppGlobalModel(),
  modelExceptionError: new ExceptionError(),
  modelIndex: new Index(),
  modelAPPDetail: new APPDetail(),
  modelPurchase: new Purchase(),
  modelAppServicePlatform: new AppServicePlatform(),
  modelAppServicePlatformMore: new AppServicePlatformMore(),
  modelAppServicePlatformDetail: new AppServicePlatformDetail(),
  modelAppServicePlatformSearch: new AppServicePlatformSearch(),
  modelOrderDetail: new OrderDetail(),
  modelAPPSearch: new APPSearch(),
  modelAPPDeveloper: new APPDeveloper(),
  modelAPPSolution: new APPSolution(),
  modelAPPSolutionDetail: new APPSolutionDetail(),
  modelAPPSolutionSearch: new APPSolutionSearch(),
  modelAPPSolutionConsult: new APPSolutionConsult(),
};
