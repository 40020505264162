/*
 * @Author: luoxia
 * @Date: 2021-10-14 15:26:19
 * @LastEditors: luoxia
 * @LastEditTime: 2022-06-01 14:37:47
 */
import { extendObservable, action } from 'mobx';

import { getSolutionDetail } from '../service';

export default class APPSolutionDetail {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      loading: false, // 是否显示加载状态
      submiting: false,
      solutionInfo: null,
      bannersInfo: [],
      //每行最多展示个数
      colNum: 4,
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  @action getSolutionDetail = (appId) => {
    return getSolutionDetail(appId).then(({ data, success }) => {
      if (success) {
        this.solutionInfo = data;
      }
    });
  };

  // 根据当前页面宽度以及单个视频宽度计算单行可展示视频数量
  @action calculateCurrentLineAppNum = (docWidth) => {
    const paddingWidth = 80 * 2;
    const APPWidth = 400;
    const contentWidth = docWidth - paddingWidth;
    const num = parseInt(contentWidth / APPWidth, 10);

    this.colNum = num > 4 ? 4 : num;
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };
}
