import App from 'next/app';
import React from 'react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import store from '../store/index';

import { Provider } from 'mobx-react';

import { envInit } from '@utils/helper';

import '../styles.css';

envInit();

class MyMobxApp extends App {
  state = {
    store,
  };

  // fetch Init state
  static async getInitialProps(appContext) {
    try {
      const appProps = await App.getInitialProps(appContext);
      let pageModelNames = appContext.ctx.pageModelNames;

      const { pageProps } = appProps;
      // 兼容单个值
      if (typeof pageModelNames === 'string') {
        pageModelNames = [pageModelNames];
      }
      let initialStoreState = pageModelNames ? pageProps : {};

      // console.log(Object.keys(pageProps));
      const plainPageProps = Object.keys(pageProps).reduce((result, next) => {
        return { ...result, ...pageProps[next] };
      }, {});
      // console.log(plainPageProps);
      return {
        pageProps: plainPageProps,
        initialStoreState,
      };
    } catch (err) {
      // This will work on both client and server sides.
      console.log(
        'The Error happened in: ',
        typeof window === 'undefined' ? 'Server' : 'Client'
      );
      return {};
    }
  }

  // Hydrate serialized state to store
  static getDerivedStateFromProps(props, state) {
    if (props.pageProps.staticProps) {
      Object.keys(props.pageProps.staticProps).forEach((item) => {
        state.store[item] &&
          state.store[item].commit(props.pageProps.staticProps[item]);
      });
    }

    return state;
  }

  render() {
    const { Component, pageProps } = this.props;
    // console.log(pageProps);
    return (
      <ConfigProvider locale={zhCN}>
        <Provider {...store}>
          <Component {...pageProps} />
        </Provider>
      </ConfigProvider>
    );
  }
}
export default MyMobxApp;
