import { extendObservable, action, computed } from 'mobx';

import { evalTimeTransform } from '../utils/helper';

import {
  queryDetailInfoById,
  getAppPurchaseStatus,
  getAppEvaluateBaseInfo,
} from '../service.js';

export default class APPDetail {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      loading: false, // 是否显示加载状态
      submiting: false,
      APPDetailInfo: null,
      appPurchaseStatus: {},
      appEvaluateBaseInfo: null,
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };

  // 获取APP详情
  @action queryDetailInfoById = (params) => {
    return queryDetailInfoById(params).then((data) => {
      if (data.succeeded && data.content) {
        this.APPDetailInfo = data.content;
      }
      return data || {};
    });
  };

  @computed get screenshotList() {
    const { APPDetailInfo } = this;
    let screenshot = [];
    if (
      APPDetailInfo &&
      APPDetailInfo.descriptionInfo &&
      APPDetailInfo.descriptionInfo.appFeaturePhotos
    ) {
      screenshot = APPDetailInfo.descriptionInfo.appFeaturePhotos
        .split('|')
        .map((i) => ({
          src: i,
          alt: '',
        }));
    }
    return screenshot;
  }

  // 获取APP的一些状态
  @action getAppPurchaseStatus = (params) => {
    return getAppPurchaseStatus(params).then(({ succeeded, content: data }) => {
      if (succeeded) {
        this.appPurchaseStatus = data;
      }
    });
  };

  // 应用评论信息
  @action getAppEvaluateBaseInfo = (params) => {
    return getAppEvaluateBaseInfo(params).then(
      ({ succeeded, content: data }) => {
        if (succeeded) {
          const result = data;
          const { evaluateList } = result;
          const evalDetailListFormate = evaluateList.map((item) => {
            const itemValue = item;
            const { createdTime } = itemValue;
            itemValue.createdTime = evalTimeTransform(createdTime);
            return itemValue;
          });
          result.evaluateList = evalDetailListFormate;

          this.appEvaluateBaseInfo = result;
        }
      }
    );
  };
}
