/*
 * @Author: 罗侠 luoxia@supos.com
 * @Date: 2021-04-15 09:50:15
 * @LastEditors: 罗侠 luoxia@supos.com
 * @LastEditTime: 2023-03-07 11:01:29
 * @FilePath: \apps-ssr-frontend\src\models\Global.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { extendObservable, action } from 'mobx';

export default class Global {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      authenticated: false,
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };
}
