import { extendObservable, action } from 'mobx';

import { getDeveloperDetailInfo, getDeveloperAPPList } from '../service';

import { uniqueObjArray } from '../utils/helper';

export default class APPDeveInfo {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      loading: false, // 是否显示加载状态
      submiting: false,
      developerData: null,
      developerAPPList: [],
      totalCount: 0,
      pageNo: 1,
      hasMore: true,
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 获取开发者信息
  @action getDeveloperDetailInfo = (params) => {
    return getDeveloperDetailInfo(params).then((data) => {
      if (data.succeeded && data.content) {
        this.developerData = data.content;
      }
      return data || {};
    });
  };

  @action getDeveloperAPPList = (params) => {
    return getDeveloperAPPList(params).then(
      ({ success, items, totalCount }) => {
        if (success) {
          // 去重后再赋值
          this.developerAPPList = uniqueObjArray(
            [...this.developerAPPList, ...items],
            'applicationID'
          );

          // 更新下次滚动的页码
          const nextPageNo = params.pageNo + 1;
          this.pageNo = nextPageNo;

          console.log(this.developerAPPList, 'this.developerAPPList');

          if (this.developerAPPList.length >= totalCount) {
            this.hasMore = false;
          }
        }

        return success;
      }
    );
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };
}
