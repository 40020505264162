import { extendObservable, action } from 'mobx';
import { getAppsPageListByType } from '../service';

export default class AppServicePlatformMore {
  constructor() {
    this.reset(true);
  }

  @action
  reset = (init) => {
    const state = {
      loading: false, // 是否显示加载状态
      hasMore: false,
      apps: [],
      pageNoParam: {
        pageNo: 1,
        pageSize: 15,
        parentClassifyId: '',
        classifyId: '-1',
      },
    };

    if (init) {
      extendObservable(this, state);
    } else {
      Object.keys(state).forEach((key) => (this[key] = state[key]));
    }
  };

  // 同步变更状态
  @action
  commit = (payload) => {
    Object.keys(payload).forEach((key) => (this[key] = payload[key]));
  };

  @action updateParentClassifyId = (value) => {
    this.pageNoParam.parentClassifyId = value;
  };

  @action getAppsPageListByType = (params) =>
    getAppsPageListByType(params).then(({ success, items, totalCount }) => {
      if (success) {
        this.apps = this.apps.concat(...items);

        this.setParamAndHasMoreStatus(totalCount);
      }
      return success;
    });

  // 上拉加载状态设置
  @action setParamAndHasMoreStatus = (totalCount) => {
    const hasMore = this.apps.length < totalCount;

    // this.updatePageNoParam();
    this.updateHasMoreFlag(hasMore);
  };

  @action updateHasMoreFlag = (hasMore) => {
    this.hasMore = hasMore;
  };

  @action updatePageNoParam = () => {
    this.pageNoParam.pageNo = this.pageNoParam.pageNo + 1;
  };

  @action clearAppsData = () => {
    this.apps = [];
  };

  @action initPageNoParam = () => {
    this.pageNoParam.pageNo = 1;
  };

  @action updateClassifyId = (value) => {
    this.pageNoParam.classifyId = value;
  };
}
